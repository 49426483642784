import React, { useState, useMemo, useContext, useEffect } from "react";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useDoc } from "../../../context/docContext";
import { styled } from "@mui/material/styles";
import { getApi } from "../../../util/HttpService";
import { notify } from "../../../util/notify";
import Loader from "../loader/loader";

const Table = ({ jsonData, columnFields }) => {
  const navigate = useNavigate();
  const { setDocData, setGlobalDocUrl } = useDoc();
  const [selectedDocument, setSelectedDocument] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedData = useMemo(() => {
    return jsonData.map((item) => {
      const newItem = { ...item };
      ["CreatedOn", "ModifiedOn", "LoginTime"].forEach((key) => {
        if (newItem[key]) {
          newItem[key] = formatDate(newItem[key]);
        }
      });
      return newItem;
    });
  }, [jsonData]);
  const columns = useMemo(() => {
    if (!formattedData[0]) {
      return [];
    }
    //console.log("column fields: ", columnFields);

    const firstItem = formattedData[0];
    const columnFieldLookup = columnFields.reduce((acc, field) => {
      const key = Object.keys(field)[0];
      acc[key] = field[key];
      return acc;
    }, {});

    return Object.keys(firstItem).flatMap((key) => {
      if (!columnFieldLookup[key]) {
        return [];
      }

      if (typeof firstItem[key] === "object" && firstItem[key] !== null) {
        return columnFields.map((field) => {
          const key = Object.keys(field)[0];
          return {
            accessorKey: `${key}`,
            header: field[key],
            size: 150,
          };
        });
      } else {
        return [
          {
            accessorKey: key,
            header: columnFieldLookup[key],
            size: 150,
          },
        ];
      }
    });
  }, [formattedData, columnFields]);

  const data = useMemo(() => [...formattedData], [formattedData]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0,
      },
    },
    initialState: { columnVisibility: { Id: false } },
    muiCircularProgressProps: {
      color: "secondary",
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 55,
    },
    enablePagination: true,
    paginationDisplayMode: "pages",
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: "50vh", overflow: "auto", minHeight: "45vh" },
    },

    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? "#4bade00a" : "#fff",
      },
    }),
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: (event) => {
        // const isDocumentNameColumn = cell.column.id === 'DocumentName';
        const documentPath = cell.row.original.DocumentPath;
        const documentNumber = cell.row.original.DocumentNo;
        const docID = cell.row.original.Id;
        const currentData = cell.row.original;

        const formData = formattedData.filter(
          (doc) => doc.FormNo === cell.row.original.FormNo
        );

        if (formData[0].FormUrl) {
          let formUrl = formData[0].FormUrl;
          let formName = formData[0].FormName;
          // //console.log("Form URl: ", formUrl, " Form Name: ", formData[0].FormName);
          syncGetFormData(formUrl, formName);
        }
        let documentData = formattedData.filter((doc) => doc.Id === docID);
        console.log(
          "Document Data: ",
          documentData,
          " format data: ",
          formattedData
        );
        if (documentData[0].DocUrl) {
          let docUrl = documentData[0].DocUrl.split("/")
            .pop()
            .replace(/\s/g, "%20");
          setDocData(documentData);
          handleDocumentClick(documentPath, docUrl);
        }
      },
    }),
  });

  const syncGetFormData = async (formUrl, formName) => {
    try {
      const formBase64Data = await getFormData(formUrl);
      downloadBase64AsDocx(formBase64Data, formName);
    } catch (error) {
      console.error(`Error getting forFm data for: ${formUrl}`, error);
    }
  };

  const downloadBase64AsDocx = (base64String, fileName) => {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileNameWithExtension = `${fileName}.docx`;
    downloadLink.setAttribute("href", linkSource);
    downloadLink.setAttribute("download", fileNameWithExtension);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getPdfUrl = async (fileName) => {
    const endpoint = `getpdfbyname?pdffileName=${fileName}`;
    const data = await getApi(endpoint, true);
    if (data.success === false) {
      notify("Error getting pdf", "error");
    }
    return data;
  };

  const getFormData = async (formUrl) => {
    const endpoint = `forms/download?formUrl=${formUrl}`;
    const data = await getApi(endpoint, true);
    //console.log("Form data:  recived ", data);
    return data.data;
  };

  function base64ToBlobUrl(base64String, mimeType) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: mimeType });
    return URL.createObjectURL(blob);
  }

  const handleDocumentClick = async (documentPath, docUrl) => {
    setIsLoading(true);
    setSelectedDocument(documentPath);
    try {
      const base64Data = await getPdfUrl(docUrl);
      //console.log(base64Data.length);
      if (base64Data === null) {
        //console.error("base64Data is null");
        return;
      }
      const htmlContent = await base64ToHtmlUrl(base64Data.data);
      //console.log(htmlContent, "htmlContent");
      if (htmlContent === null) {
        return;
      }
      setGlobalDocUrl(htmlContent);
      navigate("/Documents");
    } catch (error) {}

    setIsLoading(false);
  };

  /**
   * Converts a base64 encoded string to a blob URL.
   * This is used to convert a base64 encoded PDF to a blob URL
   * so that it can be used as the src attribute of an iframe.
   * @param {string} base64String - The base64 encoded string to convert
   * @returns {Promise<string>} - The blob URL of the converted string
   */
  const base64ToHtmlUrl = async (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  return (
    <div className="tableContainer">
      {isLoading && <Loader />}

      <MaterialReactTable table={table} />
    </div>
  );
};

export default Table;
