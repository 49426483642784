import React, { useState, useEffect, useRef } from "react";
import "./DMS_PdfViewer.css";
import { useDoc } from "../../../context/docContext";
import { customCSS } from "../../../util/DocumentCSS";
function PDFViewer({ documentUrl }) {
  const { globalDocUrl } = useDoc();
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  // Custom CSS to be injected
  // const customCSS = `
  //   body {
  //     font-family: Calibri, sans-serif;
  //     line-height: 1.6;
  //     color: #333;
  //   }
  //   h1, h2, h3, h4, h5, h6 {
  //     color: #2c3e50;
  //   }
  //   p {
  //     margin-bottom: 1em;
  //   }
  //   label {
  //     font-weight: bold;
  //     margin-right: 0.5em;
  //   }
  //   a {
  //     color: #3498db;
  //     text-decoration: none;
  //   }
  //   a:hover {
  //     text-decoration: underline;
  //   }
  //   table {
  //     border-collapse: collapse;
  //     width: 100%;
  //   }
  //   th,
  //   td {
  //     padding: 0.5em;
  //     border: 1px solid #ddd;
  //   }
  //   th {
  //     text-align: left;
  //     background-color: #4CAF50;
  //     color: white;
  //   }
  // `;

  useEffect(() => {
    if ((iframeLoaded && iframeRef.current)) {
      console.log("Injecting css");

      const iframeDocument = iframeRef.current.contentDocument;
      const SelectedIframeDocument = iframeRef.current;
      console.log("SelectedIframeDocument", iframeRef.current);
      if (!iframeDocument.head) {
        const head = iframeDocument.createElement("head");

        iframeDocument?.documentElement?.insertBefore(head, iframeDocument.body);
      }
      let styleTag = iframeDocument.querySelector("style");
      console.log("styleTag", styleTag);
      if (!styleTag) {
        styleTag = iframeDocument.createElement("style");
        iframeDocument.head.appendChild(styleTag);
      }

      styleTag.textContent = customCSS;
      // const style = iframeDocument.createElement("style");
      // iframeDocument.head.appendChild(style);
      iframeDocument.body.classList.add("editor-content");
    }
  }, [iframeLoaded, globalDocUrl]);
  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <div style={{ height: "100%" }} className={`fileViewer `}>
      <iframe
        id="pdfViewer"
        className="pdf"
        src={globalDocUrl}
        style={{
          width: "100%",
          aspectRatio: "4 / 3",
          height: "100%",
          margin: 0,
          padding: 0,
        }}
        ref={iframeRef}
        onLoad={handleIframeLoad}
      >
        <p>
          Your browser does not support PDFs.{" "}
          <a href={globalDocUrl}>Download the PDF</a>.
        </p>
      </iframe>
    </div>
  );
}
export default PDFViewer;
