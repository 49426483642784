import React from 'react'
import './loader.css'
const Loader = () => {
    return (
        <>
            <div className="loaderComponent">
                <span className="loaderBar"></span>
                <span className="loaderBar"></span>
                <span className="loaderBar"></span>
            </div>
        </>
    )
}

export default Loader