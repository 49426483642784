import React, { useState, useRef, useEffect } from "react";
import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import "./htmlEditor.css";
import Toolbar from "../htmlEditorToolbar/toolbar";
import { useDoc } from "../../../context/docContext";
import { postApi, putApi, putHTMLApi } from "../../../util/HttpService";
import { notify } from "../../../util/notify";
import SaveIcon from "@mui/icons-material/Save";
import { Buffer } from "buffer";
import { toast } from "react-toastify";
import { customCSS } from "../../../util/DocumentCSS";

const HtmlEditor = ({ editing, DocumentData, setEditDocs }) => {
  const { globalDocUrl, setGlobalDocUrl } = useDoc();
  const [selectedImage, setSelectedImage] = useState(null);
  const [content, setContent] = useState("");
  const editorRef = useRef(null);
  const iframeRef = useRef(null);
  const toastId = useRef(null);

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const inlineStyles = {
    body: "font-family: Calibri, sans-serif;",
    p: "font-family: Calibri, sans-serif;",
    label: "font-family: Calibri, sans-serif;",
    "h1, h2, h3, h4, h5, h6": "font-family: Calibri, sans-serif;",
    li: "font-family: Calibri, sans-serif;",
    table:
      "border-collapse: collapse; width: 100%; border: 1px solid black; padding-block: 1rem;",
    "th, td": "padding: 1rem 0.5rem; border: 1px solid black;",
    th: "text-align: left; background-color: #4CAF50; color: white;",
    "table tr:first-child": "background-color: #d9e2f3;",
    td: "border-collapse: collapse; width: 100%; border: 1px solid black;  padding: 1rem 0.5rem;",
    th: "border-collapse: collapse; width: 100%; border: 1px solid black;  padding: 1rem 0.5rem;",
    table:
      "border: 1px solid black; border-collapse: collapse; width: 100%; padding-block: 1rem;",
  };

  useEffect(() => {
    if (globalDocUrl && globalDocUrl.startsWith("blob:")) {
      fetchAndConvertBlobToHtml();
    } else {
      setContent("");
    }
  }, [globalDocUrl]);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
      setIframeLoaded(true);
    }
  }, [content]);

  useEffect(() => {
    if (editing && editorRef.current) {
      editorRef.current.focus();
    }
  }, [editing]);

  const fetchAndConvertBlobToHtml = async () => {
    try {
      const response = await fetch(globalDocUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const text = await blob.text();

      setContent(injectInlineStyles(text));
    } catch (error) {
      console.error("Error fetching or converting Blob:", error);
      setContent("");
    }
  };

  const injectInlineStyles = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const applyStyles = (element) => {
      const tagName = element.tagName.toLowerCase();
      if (inlineStyles[tagName]) {
        element.style.cssText += inlineStyles[tagName];
      }
      if (tagName === "a") {
        element.onmouseover = () => {
          element.style.textDecoration = "underline";
        };
        element.onmouseout = () => {
          element.style.textDecoration = "none";
        };
      }
      Array.from(element.children).forEach(applyStyles);
    };

    applyStyles(doc.body);

    return doc.body.innerHTML;
  };

  const handleEditClick = () => {
    if (iframeRef.current) {
      const iframeContent = iframeRef.current.contentDocument.body.innerHTML;
      setContent(injectInlineStyles(iframeContent));
    }
  };

  const handleSaveClick = async() => {
    if (editorRef.current) {
      const updatedContent = editorRef.current.innerHTML;
      setContent(updatedContent);
      if (iframeRef.current) {
        const iframeDoc = iframeRef.current.contentDocument;
        // iframeDoc.open();
        // iframeDoc.write(updatedContent);
        // iframeDoc.close();
      }
      if (updatedContent == "<p><br><br></p>\n                    ") {
        toast.dismiss(toastId.current);
        toastId.current = toast("Document content cannot be empty", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }
      const updateData = {
        docId: DocumentData.Id,
        content: updatedContent,
        documentName: DocumentData.DocUrl,
      };
      console.log("updateData: ", updateData);
      handleFileUpload(updateData, updatedContent);
      const base64Content = Buffer.from(updatedContent).toString("base64");
      const blob = await base64ToHtmlUrl(base64Content);
      setGlobalDocUrl(blob);
      setEditDocs(!editing);
    } else {
      console.log("iframeRef.current is null");
    }
  };

  const handleFileUpload = async (file, htmlContent) => {
    console.log("file: ", file, " htmlContent: ", htmlContent);
    const response = await putApi(
      "documentContent/updateDocumentContent",
      file,
      true
    );
    console.log("response: ", response);
    if (response.success === false) {
      notify("Document update failed", "error");
    } else {
     
      notify("Document updated successfully", "success");
    }
  };

  const base64ToHtmlUrl = async (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  const handleImageInsert = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement("img");
      img.src = e.target.result;
      img.addEventListener("click", () => setSelectedImage(img));

      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Paper elevation={3} className="html-editor">
      {!editing ? (
        <div className="d-none">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={handleEditClick}
            className="edit-button"
          >
            Edit
          </Button>
          <iframe
            ref={iframeRef}
            title="HTML Content"
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        </div>
      ) : (
        <>
          <div className="button-group ">
            <Tooltip title="Save Document">
              <IconButton
                className="bg-success text-white"
                onClick={handleSaveClick}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Toolbar editorRef={editorRef} onImageInsert={handleImageInsert} />
          <div
            ref={editorRef}
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: content }}
            className="editor-content"
          />
        </>
      )}
    </Paper>
  );
};

export default HtmlEditor;
