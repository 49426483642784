import React, { useEffect, useRef, useState } from 'react'
import './SearchBar.css'
import { getApi } from "../../../util/HttpService";
import SearchIcon from '@mui/icons-material/Search';
import { notify } from '../../../util/notify';
import { ClearIcon } from '@mui/x-date-pickers';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';

const SearchBar = ({ setSearchResponse, setTableData, setShowSkeleton }) => {
    const [searchText, setSearchText] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const toastId = useRef(null);

    useEffect(() => {
        //console.log("searchResults in search bar", searchResults)
        setSearchResponse(searchResults)
    }, [searchResults])

    const handleSearch = async (e) => {
        if (searchText === '') {
            if (toast.isActive(toastId.current)) {
                return false
            }
            toastId.current = notify("Please enter something", "error");
            return
        }
        if (e.key === 'Enter' || e.type === 'click') {
            e.preventDefault()
            setShowSkeleton(true)
            const response = await getApi(`documentContent?query=${searchText}`, true)
            if (response.result.length === 0) {
                notify("No results found", "error");
            }

            // //console.log("response", response.result)
            else
                setSearchResults(response.result)

            setShowSkeleton(false)
        }
    }

    const handleSearchInput = (e) => {
        const regex = /^[a-zA-Z0-9\s.,-]*$/;
        if (regex.test(e.target.value)) {
            setSearchText(e.target.value)
        }
        else {
            notify("Invalid input", "error");
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e)
        }
    }


    const handleClear = () => {
        setSearchText('')
        setSearchResults([])
        setTableData([])
        //console.log("cleared search text from search bar", searchText, " and search results", searchResults)
    }
    return (
        <>
            <div className="SearchBarContainer">
                <input type="text" placeholder="Search" className="searchBar" min={0} maxLength={30} value={searchText}
                    onChange={handleSearchInput}
                    onKeyDown={handleKeyDown}
                />
                <IconButton className="clearBtn" onClick={handleClear} >
                    <ClearIcon />
                </IconButton>
                <IconButton className="searchBtn" onClick={handleSearch} >
                    <SearchIcon />
                </IconButton>
            </div>
            {/* {searchResults.length > 0 && <div className="searchResults">
                {searchResults.map(result => <div key={result.id}>{result.name}</div>)}
            </div>
            } */}
        </>
    )
}

export default SearchBar

