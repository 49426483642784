import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Table from "../../../../shared/components/ActionTable/ActionTable";
import "./User.css";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getApi } from "../../../../util/HttpService";
import UpdateUser from './../UpdateUser/UpdateUser';
import AddUser from './../../components/User/AddUser';
const User = () => {
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const columnFields = [
        { UserName: "User Name" },
        { FullName: "Full Name" },
        { Email: "Email" },
        { ContactNo: "Contact Number" }
    ];
    useEffect(() => {

        const getDocuments = async () => {
            try {
                const response = await getApi('getUserDetails', true);

                if (response.success) {
                    // const data = await response.json();
                    const dataArray = response.data;
                    //console.log("dataArray: ", dataArray)

                    let filterData = dataArray.map(({
                        userId,
                        EmployeeID,
                        UserName,
                        FullName,
                        FirstName,
                        LastName,
                        Email,
                        ContactNo,
                        IsActive,
                        CreatedOn,
                        ModifiedOn,
                        RoleId

                    }) => ({
                        Id: userId,
                        EmployeeID,
                        UserName,
                        FullName,
                        FirstName,
                        LastName,
                        Email: Email.trim(),
                        ContactNo,
                        IsActive,
                        CreatedOn,
                        ModifiedOn,
                        RoleId
                    }));

                    console.log("response filtered data: ", filterData);
                    setFilteredData(filterData);

                } else {
                    // const errorData = await response.json();
                    //console.log(`Document fetch failed.`);
                }
            } catch (error) {
                //console.log(`Document fetch failed: ${error}`);
            } finally {
            }
        };
        getDocuments();
    }, []);

    const handleClick = () => {
        navigate("/add-user");
    }

    const handleEdit = (row) => {
        // //console.log(row);
        navigate("/update-user", { state: row.original });
    };

    return (
        <div className="UserDashboard dashboard-container">
            <PageHeader title="Users" />

            <div className="sub-dashboard-content">
                <div className="header-container">
                    <button className="add-user-btn" onClick={handleClick}><AddIcon />Add User</button>
                </div>
                <div className="table-container">
                    {filteredData && filteredData.length > 0 ? (
                        <Table jsonData={filteredData} handleEdit={handleEdit} columnFields={columnFields} setTableData={setFilteredData} />
                    ) : (
                        <Skeleton height="50vh" />
                    )}
                </div>
            </div>
        </div>
    );

};

export default User;
