import React, { createContext, useContext, useState } from 'react';

const DmsContext = createContext();

export const useDms = () => useContext(DmsContext);

export const DmsDocs = ({ children }) => {
    const [docSubTitle, setDocSubTitle] = useState('Manuals');
    const [categoryName, setCategoryName] = useState('');
    const [docInfo, setDocInfo] = useState(null);
    const [formInfo, setFormInfo] = useState(null);
    return (
        <DmsContext.Provider value={{ categoryName, setCategoryName, docSubTitle, setDocSubTitle, docInfo, setDocInfo, formInfo, setFormInfo }}>
            {children}
        </DmsContext.Provider>
    );
};
