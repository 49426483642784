import React, { useCallback, useEffect, useState } from "react";
import './DMS_dashboard.css';
import { useNavigate, NavLink, useParams } from 'react-router-dom';

import PageHeader from "../../components/pageHeader/PageHeader";
import SidebarAccordion from "../../components/Accordian/Accordian";
import { useDms } from "../../../context/dmsContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Table from "../../components/Table/Table";
import { useAuth } from "../../../context/authContext";
import Loader from "../../components/loader/loader";
import { getApi } from "../../../util/HttpService";
import { useData } from "../../../context/dataContext";

const App = () => {
  const { docInfo, docSubTitle, categoryName, formInfo } = useDms();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { ownerDepartmentList } = useData();



  useEffect(() => {
    if (docInfo) {

      setTableData(docInfo.map((document) => ({
        ...document,
        OwnerDepartment: ownerDepartmentList.find((dept) => dept.Id == document.OwnerDepartment)
          ? ownerDepartmentList.find((dept) => dept.Id == document.OwnerDepartment).Name
          : "",
      })));
      setShowSkeleton(false);
    }
  }, [docInfo]);

  const columnFields = [
    { DocumentNo: "Document Number" },
    { DocumentName: "Document Name" },
    { DocumentPath: "Document Path" },
    { DocumentVersion: "Document Version" },
    { CreatedOn: "Created On" },
    { ModifiedOn: "Modified On" },
    { UpdatedByName: "Modified By" },
    { OwnerDepartment: "Owners" },
  ];

  const formFields = [
    { FormNo: "Form Number" },
    { FormName: "Form Name" },
    { FormPath: "Form Path" },
    { FormVersion: "Form Version" },
    { CreatedOn: "Created On" },
    { ModifiedOn: "Modified On" },
    { OwnerDepartment: "Owners" },
    { UploadedBy: "Uploaded By" },
  ];

  //console.log("formInfo", formInfo);
  return (
    <>

      <div className='dms_dashboard'>
        {/* <SidebarAccordion sidebarItems={elementlist} /> */}
        <div className='dms_dashboard_table'>
          <div className='headBlock'>
            {/* <h2 className='title'>Document Management System</h2> */}
            <PageHeader title="Document Management System" />
            <h6 className='subTitle'>{docSubTitle}</h6>
            <p>{categoryName}</p>
          </div>

          <div className='tableBlock'>
            {
              showSkeleton ? (
                <Loader />
              ) : (
                docSubTitle === "Manuals" && tableData ? (
                  <Table key={"Manuals"} jsonData={tableData} columnFields={columnFields} />
                ) : (
                  docSubTitle === "Forms" && formInfo ? (
                    <Table key={"Forms"} jsonData={formInfo} columnFields={formFields} />
                  ) : (
                    null
                  )
                )
              )
            }



          </div>

        </div >
      </div >


    </>
  );
};

export default App;
