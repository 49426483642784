import React, { useMemo } from "react";
import { MenuItem } from "@mui/material";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { deleteApi, getApi } from "../../../../util/HttpService";
import { notify } from "../../../../util/notify";
import { useAuth } from "../../../../context/authContext";

// import mime from 'mime';

const Table = ({ jsonData, columnFields, setTableData }) => {
  const { userId, isAdmin } = useAuth();

  const columns = useMemo(() => {
    if (!jsonData[0]) {
      return [];
    }
    const firstItem = jsonData[0];
    // //console.log("first item: ", firstItem);
    const columnFieldLookup = columnFields.reduce((acc, field) => {
      const key = Object.keys(field)[0];
      acc[key] = field[key];
      return acc;
    }, {});
    return Object.keys(firstItem).flatMap((key) => {
      if (!columnFieldLookup[key]) {
        return [];
      }
      if (typeof firstItem[key] === "object" && firstItem[key] !== null) {
        return columnFields.map((field) => {
          const key = Object.keys(field)[0];
          return {
            accessorKey: `${key}`,
            header: field[key],
            size: 150,
          };
        });
      } else {
        return [
          {
            accessorKey: key,
            header: columnFieldLookup[key],
            size: 150,
          },
        ];
      }
    });
  }, [jsonData, columnFields]);

  const data = useMemo(() => [...jsonData], [jsonData]);

  const handleDocumentDownload = async (DocumentName, docUrl, rowData) => {
    //console.log("row data", rowData.docUrl);
    const response = await getApi(
      `generalDocuments/download/${rowData.docUrl}`,
      true
    );
    //console.log("response", response);
    if (response.success === false) {
      notify("Document download failed", "error");
    } else {
      const base64String = response.data;
      //console.log("Doc  url: ", rowData.docUrl)
      getBase64Url(rowData.docUrl, base64String);
    }
  };

  function getBase64Url(filename, base64Url) {
    const mimeTypes = {
      txt: "text/plain",
      pdf: "application/pdf",
      html: "text/html",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      doc: "application/msword",
      rtf: "application/rtf",
    };

    const extension = filename.split(".").pop().toLowerCase();
    const mimeType = mimeTypes[extension] || "application/octet-stream";

    const link = document.createElement("a");
    link.href = `data:${mimeType};base64,${base64Url}`;
    link.download = filename;
    link.click();
  }

  function downloadBase64File(base64String, fileName) {
    // Decode the Base64 string in a way that handles Unicode characters
    const binaryString = Uint8Array.from(atob(base64String), (c) =>
      c.charCodeAt(0)
    );

    // Create a Blob from the Uint8Array
    const blob = new Blob([binaryString], { type: "application/octet-stream" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const downloadBase64AsDocx = (base64String, fileName) => {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileNameWithExtension = `${fileName}.docx`;
    downloadLink.setAttribute("href", linkSource);
    downloadLink.setAttribute("download", fileNameWithExtension);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // function base64ToFile(base64String, fileName) {
  //     // Extract mime type from file name
  //     const mimeType = mime.getType(fileName) || 'application/octet-stream';

  //     // Remove data URL scheme if present
  //     const base64Data = base64String.replace(/^data:.+;base64,/, '');
  //     const byteCharacters = atob(base64Data); // Decode Base64 string
  //     const byteNumbers = new Array(byteCharacters.length);

  //     for (let i = 0; i < byteCharacters.length; i++) {
  //         byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: mimeType });
  //     const url = URL.createObjectURL(blob);

  //     // Create a link element to download the file
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = fileName;
  //     link.click();

  //     // Cleanup
  //     URL.revokeObjectURL(url);
  // }

  const handleDeleteDoc = async (DocId) => {
    try {
      if (!DocId) {
        notify("Document deletion failed", "error");
        return;
      }
      const response = await deleteApi(`generalDocuments/${DocId}`, true);
      //console.log("response", response);
      if (response.success === false) {
        notify("Document deletion failed", "error");
      } else {
        notify("Document deleted successfully", "success");
        setTableData((prevData) =>
          prevData.filter((item) => item.Id !== DocId)
        );
      }
    } catch (error) {
      //console.log("error", error);
      notify("Document deletion failed", "error");
    }
    // navigate(-1);
  };

  // Use the useMaterialReactTable hook
  const table = useMaterialReactTable({
    columns,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableContainerProps: {
      sx: { maxHeight: "50vh", overflow: "auto", minHeight: "45vh" },
    },
    renderRowActionMenuItems: ({ row }) => {
      const menuItems = [
        <MenuItem
          key="edit"
          onClick={() =>
            handleDocumentDownload(
              row.original.DocumentName,
              row.original.DocUrl,
              row.original
            )
          }
        >
          Download
        </MenuItem>,
      ];

      if (isAdmin) {
        menuItems.push(
          <MenuItem
            key="delete"
            onClick={() => handleDeleteDoc(row.original.Id)}
          >
            Delete
          </MenuItem>
        );
      }

      return menuItems;
    },
    muiTableBodyCellProps: {
      sx: {
        "& .MuiIconButton-root": {
          transform: "rotate(90deg)",
        },
      },
    },
    data,
  });

  return (
    <div className="dashboard-container">
      <div className="sub-dashboard-content">
        <div className="table-container">
          {jsonData && jsonData.length > 0 ? (
            <MaterialReactTable table={table} />
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
