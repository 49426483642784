import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BackgroundImg from "../../../Assets/Images/shipbanner.jpg";
import Logo from "../../../Assets/Images/loginLogo.png";
import { useAuth } from "../../../context/authContext";
import OtpSection from "../../components/otp/otp";

import { notify } from "../../../util/notify";
import { addFormDocumentData, postApi } from "../../../util/HttpService";

const PageContainer = styled(Box)({
  display: "flex",
  height: "100vh",
});

const ImageSection = styled(Box)({
  flex: 1,
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const FormSection = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  backgroundColor: "#ffffff",
});

const FormContainer = styled(Box)({
  width: "90%",

});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#f5f5f5',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const BrandLogo = styled(Box)({
  backgroundImage: `url(${Logo})`,

});
const StyledButton = styled(Button)({
  backgroundColor: '#3498db',
  textTransform:"initial",
  borderRadius: '20px',
  padding: '10px 0',
  '&:hover': {
    backgroundColor: '#2980b9',
  },
});




const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [userIdError, setUserIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailField, setEmailField] = useState('');


  const [showOtp, setShowOtp] = useState(false);

  const { isLoggedIn, setIsLoggedIn, setIsAdmin, isAdmin, setToken, setIsAuthenticated, getMe } = useAuth();



  const validateForm = () => {
    const usernameRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

    //console.log("username", emailField, "password", password);
    let isValid = true;

    if (!emailField || !usernameRegex.test(emailField)) {
      setUserIdError("Please enter a valid email address");
      isValid = false;
    } else {
      setUserIdError("");
    }

    if (!password || !passwordRegex.test(password)) {
      setPasswordError("Password must be at least 8 characters long and contain one uppercase letter, one lowercase letter, one number and one special character");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };




  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e)
    }
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value.trim());
  };
  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    try {
      const response = await addFormDocumentData('loginapi', { email: emailField, password: password }, false);
      //console.log("Response from login api", response);
      if (!response.success) {
        notify("Login failed", 'error');
        return;
      }

      const { isAdmin, token } = response.data;

      setIsAdmin(isAdmin);
      setToken(token);
      localStorage.setItem('isAdmin', isAdmin);
      localStorage.setItem('token', token);
      getMe(token);
      // uncomment on production when otp is implemented
      // if (isAdmin) {
      //   setShowOtp(true);
      // } else {
      //   getMe(token);
      // }

    } catch (error) {
      notify(error, 'error');
    }
    finally {
      setIsLoading(false);
    }
  };
  return (
    <PageContainer>
      <ImageSection />
      <FormSection>
        <div style={{ width: "26%", display: "flex", justifyContent: "center" }}>
          <img style={{ width: "100%", height: "auto" }} src={Logo} alt="logo" />
        </div>


        <FormContainer style={{ width: "75%", display: "flex", flexDirection: "column", justifyContent: "center", paddingBlockStart: "5rem" }}>
          <Typography  variant="h4" component="h1" gutterBottom align="center" fontWeight="bold">
            Sign in 
          </Typography>
          <Box component="form" onKeyDown={handleKeyDown} onSubmit={handleLogin}>
            <StyledTextField
              sx={{ padding: '10px' }}
              margin="normal"
              required
              fullWidth
              id="userId"
              label="Mail Id"
              name="userId"
              autoComplete="Mail"
              autoFocus
              onChange={(e) => setEmailField(e.target.value.trim().toLowerCase())}
              error={!!userIdError}
              helperText={userIdError}
              variant="outlined"
              inputProps={{ maxLength: 50, minLength: 5 }}
            />
            <StyledTextField
              sx={{ padding: '10px' }}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              error={!!passwordError}
              helperText={passwordError}
              variant="outlined"
              inputProps={{ maxLength: 20, minLength: 8 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <StyledButton
              type="submit"
              disabled={isLoading}
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign in
            </StyledButton>
          </Box>

          {showOtp &&

            < OtpSection />
          }

          {/* <ToastContainer /> */}
        </FormContainer>
      </FormSection>
    </PageContainer >
  );
};

export default Login;