import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/authContext';
import { notify } from '../../../util/notify';

const OtpSection = () => {
    // State variables
    const Navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isOtpInputVisible, setIsOtpInputVisible] = useState(false);
    const [counter, setCounter] = useState(30);
    const { setIsAuthenticated, getMe, token } = useAuth();
    // Handlers
    const handleSendOtp = () => {
        notify('OTP sent', 'success');
        setIsOtpSent(true);
        setIsOtpInputVisible(true);
        setCounter(30);
    };

    const handleResendOtp = () => {
        setOtp('');
        setIsOtpSent(true);
        notify('OTP sent again', 'success');

    };

    const handleVerifyOtp = () => {
        if (otp === '123456') {
            setIsOtpVerified(true);
            notify('OTP verified', 'success');
            setIsAuthenticated(true)
            getMe(token);
            Navigate('/');
        } else {
            notify('Invalid OTP', 'error');
        }
    };

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    useEffect(() => {
        if (isOtpVerified) {
            Navigate("/");
        }
    }, [isOtpVerified, Navigate]);

    useEffect(() => {
        if (isOtpSent) {
            const timer = setInterval(() => {
                setCounter((prevCounter) => {
                    if (prevCounter === 0) {
                        setIsOtpSent(false);
                        return 0;
                    }
                    return prevCounter - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isOtpSent]);

    return (
        <>
            {!isOtpVerified &&
                <Box sx={{ display: 'flex', flexDirection: 'column', width: 'max-content', gap: 2, paddingLeft: '10px' }}>
                    {!isOtpSent && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendOtp}
                        >
                            Send OTP
                        </Button>
                    )}

                    {isOtpInputVisible && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                type="text"
                                variant="outlined"
                                size="small"
                                value={otp}
                                onChange={handleOtpChange}
                                disabled={isOtpVerified}
                                inputProps={{ maxLength: 6 }} // OTP inputs are typically short
                            />
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleResendOtp}
                                disabled={!isOtpSent || isOtpVerified || counter !== 0}
                            >
                                Re-send ({counter > 0 ? counter : 'ready'})
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleVerifyOtp}
                                disabled={isOtpVerified || otp.length < 6}
                            >
                                Verify
                            </Button>
                        </Box>
                    )}
                    {/* {isOtpVerified && <Box sx={{ mt: 2, color: 'green' }}>OTP Verified!</Box>} */}
                </Box>
            }

        </>

    );
};

export default OtpSection;
