import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table/Table";
import "./Dashboard.css";
import { useAuth } from "../../../context/authContext";
// import SidebarAccordion from "../../components/Accordian/Accordian";
import Logout from "../../components/logout/Logout";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getApi } from "../../../util/HttpService";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useData } from "../../../context/dataContext";
const Dashboard = () => {
  const { userId } = useAuth();
  // const [lastViewedCount, setLastViewedCount] = useState(0);
  // const [newManualsCount, setNewManualsCount] = useState(0);
  // const [updatedManualsCount, setUpdatedManualsCount] = useState(0);
  const { ownerDepartmentList } = useData();
  const [activeButton, setActiveButton] = useState("newManuals");
  const [documentData, setDocumentData] = useState([]);
  const [readDocumentData, setReadDocumentData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [lastViewedCount, setLastViewedCount] = useState(0);
  //console.log("Search response is : ", searchResponse, "len of searchResponse data is: ", searchResponse.length)
  // //console.log("len of table data is: ", tableData.length)
  const columnFields = [
    { DocumentNo: "Document Number" },
    { DocumentName: "Document Name" },
    { DocumentPath: "Document Path" },
    { DocumentVersion: "Document Version" },
    { CreatedOn: "Created On" },
    { UpdatedByName: "Modified By" },
    { ModifiedOn: "Modified On" },
    { OwnerDepartment: "Owners" },
  ];



  useEffect(() => {
    if (searchResponse.length > 0) {
      setShowSkeleton(true);
      const filteredData = documentData.filter((doc) =>
        searchResponse.find((readDoc) => readDoc.DocUrl === doc.DocUrl)
      );
      //console.log("Filtered Data: ", filteredData);
      setTableData(filteredData);
      setShowSkeleton(false);
      //console.log("Filtered Data: ", filteredData);
    }
    else {
      filterDocuments(activeButton, documentData);
    }
  }, [searchResponse])
  const getDocuments = async () => {
    try {
      setShowSkeleton(true);
      const response = await getApi("documentlist", true);
      if (response.success) {
        const data = response.data.data;
        //console.log("Data from the Document API is: ", data);
        setDocumentData(data);


      } else {
        console.error(`Document fetch failed: ${response.error}`);
      }

      setShowSkeleton(false);
    } catch (error) {
      console.error(`Document fetch failed: ${error}`);
    } finally {
    }
  };


  useEffect(() => {
    if (userId) {
      getDocuments();
      getReadDocsData();
    }
  }, [userId]);



  const filterDocuments = (filterType, documents) => {
    let filteredDocuments;
    switch (filterType) {
      case "lastViewed":
        filteredDocuments = readDocumentData;
        break;
      case "newManuals":
        filteredDocuments = documents
          .filter((document) => {
            const last7Days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            return new Date(document.CreatedOn.substring(0, 10)) >= last7Days;
          })
          .sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn));
        // setDocumentData(filteredDocuments);
        break;
      case "updated":
        filteredDocuments = documents
          .filter((document) => {
            if (document.ModifiedOn) {
              const last7Days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
              return new Date(document.ModifiedOn.substring(0, 10)) >= last7Days;
            }
            return null;
          })
          .sort((a, b) => new Date(b.ModifiedOn) - new Date(a.ModifiedOn));
        break;
      default:
        filteredDocuments = documents;
    }

    return filteredDocuments.map((document) => ({
      ...document,
      OwnerDepartment: ownerDepartmentList.find((dept) => dept.Id == document.OwnerDepartment)
        ? ownerDepartmentList.find((dept) => dept.Id == document.OwnerDepartment).Name
        : "",

    }));
  };



  const getReadDocsData = async () => {
    try {
      const data = await getApi("documentreadDetailsAPI", true);
      setLastViewedCount(data.data.lastViewedDocuments.length);
      setReadDocumentData(
        data.data.lastViewedDocuments.sort((a, b) => b.ReadId - a.ReadId)
      );
      console.log("Data is: ", data.data.lastViewedDocuments, " sorted data is : ", data.data.lastViewedDocuments.sort((a, b) => b.ReadId - a.ReadId));
      // setReadDocumentData(data.data.lastViewedDocuments);

    } catch (error) {
      ////console.log(`Document count fetch failed: ${error}`);
    } finally {
    }
  }
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    // setTableData(filterDocuments(buttonName, documentData));
  };

  const FilteredTableData = useMemo(() => {
    const filteredData = filterDocuments(activeButton, documentData);
    return filteredData;
  }, [activeButton, showSkeleton]);


  const LastViewedCounts = useMemo(() => {
    const filteredData = filterDocuments("lastViewed", documentData);
    return filteredData.length;

  }, [documentData]);

  const UpdatedCount = useMemo(() => {
    const filteredData = filterDocuments("updated", documentData);
    return filteredData.length;
  }, [documentData]);

  const NewManualCount = useMemo(() => {
    const filteredData = filterDocuments("newManuals", documentData);
    return filteredData.length;

  }, [documentData]);



  return (
    <div className="dashboard-container MainDashboard">
      <div className="sub-dashboard-content">
        <div className="TopNav">
          <div className="nav-bar-left">
            <button
              className={`nav-item ${activeButton === "lastViewed" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("lastViewed")}
            >
              {`Last Viewed Manuals (${lastViewedCount})`}
            </button>
            <button
              className={`nav-item ${activeButton === "newManuals" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("newManuals")}
            >
              {`New Manuals (${NewManualCount})`}
            </button>
            <button
              className={`nav-item ${activeButton === "updated" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("updated")}
            >
              {`Updated Manuals (${UpdatedCount})`}
            </button>
          </div>
          <Logout />
        </div>
        <div className="SearchBarSection">
          <SearchBar setSearchResponse={setSearchResponse} setTableData={setTableData} setShowSkeleton={setShowSkeleton} />
        </div>
        <div className="table-container">
          {showSkeleton ? (
            <div className="skeleton-container">
              <Skeleton height="50vh" />
            </div>
          ) : (
            <Table jsonData={tableData.length > 0 ? tableData : FilteredTableData} columnFields={columnFields} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

