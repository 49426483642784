import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Button,
  Tooltip,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./fileViewer.css";
import { useNavigate } from "react-router-dom";
import PDFViewer from "../../../../shared/components/pdfViewer/DMS_PdfViewer";
import { useDoc } from "../../../../context/docContext";
import { useAuth } from "../../../../context/authContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { notify } from "../../../../util/notify";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import { deleteApi, getApi, postApi } from "../../../../util/HttpService";
import HtmlEditor from "../../../../shared/components/htmlEditor/htmlEditor";
import { useData } from "../../../../context/dataContext";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import EditMetaData from "../../../../shared/components/editMetaData/editMetaData";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../../shared/components/loader/loader";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "auto",
  borderRadius: "2rem",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: "2rem",
};
export const FileViewer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { globalDocUrl, setGlobalDocUrl } = useDoc();
  const [signingLocation, setSigningLocation] = useState("");
  const [checkboxEnabled, setCheckboxEnabled] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const { docData } = useDoc();
  const { documentData } = useData();
  const { userId, isAdmin } = useAuth();
  const [itemList, setItemList] = useState([]);
  const [editMetaData, setEditMetaData] = useState(false);
  const { ownerDepartmentList, elementListData } = useData();
  const [editDocs, setEditDocs] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [cardData, setCardData] = useState(
    documentData.find((d) => docData?.some((item) => item.Id === d.Id))
  );

  const [ownerName, setOwnerName] = useState("");

  useEffect(() => {
    if (cardData) {
      setOwnerName(
        ownerDepartmentList.find((item) => item.Id === cardData.OwnerDepartment)
          ?.Name
      );
    }
  }, [ownerDepartmentList, cardData, elementListData]);

  // const cardData = docData[0]
  useEffect(() => {
    fetchItemList();
  }, []);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  useEffect(() => {
    const alreadyReadItem = itemList.find(
      (item) => item.DocumentNo === cardData?.DocumentNo
    );
    console.log("alreadyReadItem", alreadyReadItem);
    if (alreadyReadItem) {
      setCheckboxChecked(true);
      setCheckboxEnabled(false);
      setSigningLocation(alreadyReadItem.ShipOrShore.trim() || "");
    }
  }, [itemList, cardData]);

  const fetchItemList = async () => {
    try {
      const data = await getApi(`documentreadDetailsAPI`, true);
      // //console.log("Item list", data.data.lastViewedDocuments)
      setItemList(data.data.lastViewedDocuments);
    } catch (error) {}
  };
  const addReadDocumentDetail = async () => {
    const params = {
      UserId: userId,
      DocId: cardData.Id,
      DocumentReadStatus: true,
      ShipOrShore: signingLocation,
    };
    try {
      const data = await postApi("addreadDocumentDetail", params, true);
      notify("Data added successfully", "success");
      setCheckboxChecked(true);
      setCheckboxEnabled(false);
    } catch (error) {
      notify(error.message, "error");
    }
  };

  const handleAgreementCheck = () => {
    if (!checkboxChecked && signingLocation) {
      addReadDocumentDetail();
    }
  };

  const handleSigningLocation = (e) => {
    const selectedLocation = e.target.value;
    if (selectedLocation !== "Select an Option") {
      setSigningLocation(selectedLocation);
      setCheckboxEnabled(true);
    } else {
      setSigningLocation("");
      setCheckboxEnabled(false);
    }
  };
  const handleEditMetaData = () => {
    setEditMetaData(!editMetaData);
  };

  const handleSaveDocs = () => {
    setEditDocs(!editDocs);
  };
  const handleDeleteDoc = () => {
    const response = deleteApi(`deleteDocument/${cardData.Id}`, true);
    // //console.log("response", response);
    if (response.success === false) {
      notify("Document deletion failed", "error");
    } else {
      notify("Document deleted successfully", "success");
      navigate(-1);
    }
  };

  const handleEditDocs = () => {
    setEditDocs(!editDocs);
  };
  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      {cardData ? (
        <div className="FileViewer">
          <div className="TopNav">
            <div className="fileSideItems">
              <IconButton onClick={handleNavigateBack} aria-label="back">
                <ArrowBackIcon />
              </IconButton>

              <div className="">
                <p className="FileName">
                  {cardData?.DocumentNo
                    ? `${cardData.DocumentNo} | ${cardData.DocumentName}`
                    : "-"}
                </p>
                <p className="FileDescription">
                  {cardData?.DocumentPath ? cardData.DocumentPath : "-"}
                </p>
              </div>
            </div>
            {isAdmin &&
              globalDocUrl &&
              (editMetaData ? (
                <Tooltip title="Save Document" className="d-none">
                  <IconButton className="" hidden>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Edit Metadata">
                    <IconButton
                      className="bg-primary text-white"
                      onClick={handleEditMetaData}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Document">
                    <IconButton
                      className="bg-danger text-white"
                      onClick={handleDeleteDoc}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ))}
          </div>
          <div className="CardSection">
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Version</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">{cardData.DocumentVersion}</p>
            </div>
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Updated Date</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">
                {new Date(cardData.ModifiedOn).toLocaleDateString("en-GB")}
              </p>
            </div>
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Issued Date</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">
                {new Date(cardData.CreatedOn).toLocaleDateString("en-GB")}
              </p>
            </div>
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Owners</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">{ownerName}</p>
            </div>
          </div>
          <Modal
            open={editMetaData}
            onClose={() => setEditMetaData(false)}
            aria-labelledby="edit-metadata-modal-title"
            aria-describedby="edit-metadata-modal-description"
          >
            <Box sx={style}>
              <EditMetaData
                formData={cardData}
                setEditMetaData={setEditMetaData}
                setCardData={setCardData}
              />
            </Box>
          </Modal>
          {editDocs && (
            <HtmlEditor
              setEditDocs={setEditDocs}
              DocumentData={cardData}
              editing={editDocs}
            />
          )}
          {!editDocs && (
            <>
              <div
                className={`PdfViewerContainer ${
                  fullScreen ? "fullScreenMode" : ""
                }`}
              >
                <div className="IconContainer">
                  <div
                    className="EditDocsBtn"
                    style={{ display: fullScreen ? "none" : "" }}
                  >
                    {isAdmin &&
                      globalDocUrl &&
                      (editDocs ? (
                        <Button
                          className="ActionButtons activeBtn"
                          onClick={handleSaveDocs}
                          hidden
                        >
                          Save
                        </Button>
                      ) : (
                        <Tooltip title="Edit Document">
                          <IconButton
                            className="bg-primary text-white"
                            onClick={handleEditDocs}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      ))}
                  </div>
                  {globalDocUrl && (
                    <div className="TopIcons">
                      <Tooltip
                        title={fullScreen ? "Exit Full Screen" : "Full Screen"}
                      >
                        <IconButton
                          aria-label="close"
                          onClick={handleFullScreen}
                          className="fullscreenIcon bg-secondary text-white"
                        >
                          {fullScreen ? <CloseIcon /> : <FullscreenIcon />}
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
                {globalDocUrl ? (
                  <PDFViewer documentUrl={globalDocUrl} />
                ) : (
                  <Skeleton height="40vh" />
                )}
              </div>
            </>
          )}
          {!globalDocUrl && <Loader />}
          <div className="AgreementContainer">
            <div className="AgreementHeader">
              <input
                type="checkbox"
                onChange={handleAgreementCheck}
                className="AgreementCheckBox"
                checked={checkboxChecked}
                disabled={!checkboxEnabled}
              />
              <p className="AgreementText">
                I have read and understand the document
              </p>
            </div>
            <div className="AgreementFooter">
              <select
                className="AgreementSelect"
                value={signingLocation}
                onChange={handleSigningLocation}
                disabled={checkboxChecked}
              >
                <option value="">Select an Option</option>
                <option value="Ship">Ship</option>
                <option value="Shore">Shore</option>
              </select>
            </div>
          </div>
        </div>
      ) : (
        <div className="FileViewerContainer">
          <PageHeader title="File Viewer" />
          {!globalDocUrl && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="60vh"
            >
              <Typography variant="h6" align="center" gutterBottom>
                No data to preview
              </Typography>
            </Box>
          )}
        </div>
      )}
    </>
  );
};
