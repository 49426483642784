import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postApi, uploadFormData } from '../../../../util/HttpService'
import { notify } from '../../../../util/notify'

const AddGenDoc = ({ open, setOpen, setTableData }) => {
    const formik = useFormik({
        initialValues: {
            file: null,
        },
        validationSchema: Yup.object().shape({
            file: Yup.mixed().required('File is required').test("file-size", "File size should not exceed 5MB", (value) => {
                return value && value.size <= 5 * 1024 * 1024;
            }),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const docUploadResponse = await DocumentUpload(values.file);
                if (docUploadResponse.success) {
                    const addDocumentResponse = await AddDocument(values.file.name, docUploadResponse.data.docUrl);
                    if (addDocumentResponse.success) {

                        notify("Document added successfully", "success");
                        setTableData((prevData) => [...prevData, { DocumentName: values.file.name, Id: addDocumentResponse.data.result[0].Id, docUrl: docUploadResponse.data.docUrl }]);
                    } else {
                        // notify("Document addition failed", "error");
                        //console.log("Document addition failed: ", addDocumentResponse.error);
                    }
                } else {
                    // notify("Document addition failed", "error");

                    //console.log("Document upload failed: ", docUploadResponse.error);
                }
            }
            catch (error) {
                console.error('Upload error:', error);
                notify('An error occurred during upload', 'error');
            } finally {
                setSubmitting(false);
                resetForm();
                setOpen(false);
            }
            setSubmitting(false)
            resetForm()
        },
    })

    const DocumentUpload = async (file) => {
        const formdata = new FormData();
        formdata.append("file", file);
        const response = await uploadFormData('generalDocuments/upload', file, true);
        //console.log("response: ", response, " Status: ", response.success);
        if (response.success === false) {
            notify(response.error, 'error');
        }
        return response;
    }

    const AddDocument = async (DocumentName, docUrl) => {
        ////console.log(DocumentName, docUrl)
        const data =
        {
            "DocumentName": DocumentName,
            "DocUrl": docUrl
        }
        const response = await postApi('generalDocuments/addGenDocument', data, true);
        //console.log("response: ", response);
        if (response.success === false) {
            notify(response.error, 'error');
        }
        return response
    }

    const handleFileChange = (event) => {
        const selectedFile = event.currentTarget.files[0];
        //console.log("Selected file:",selectedFile)
        formik.setFieldValue("file", selectedFile);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="DocUpload">
                <p className="label">Document Upload</p>
                <input
                    type="file"
                    name="file"
                    id="fileInput"
                    className="file-input"
                    multiple={false}
                    onChange={handleFileChange}
                    onBlur={formik.handleBlur}
                // value={formik.values.file ? '' : null}
                />
                {formik.touched.file && formik.errors.file ? (
                    <div className="error">{formik.errors.file}</div>
                ) : null}
            </div>
            <div className="FileBtnContainer">
                <button type="submit" className="ActionButtons activeBtn" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? 'Uploading...' : 'Submit'}
                </button>
                <button type="button" className='ActionButtons' onClick={() => setOpen(false)}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default AddGenDoc

