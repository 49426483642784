import React, { useEffect, useState } from "react";
// import Table from "../../components/ReadAndUnderstand/readAndUnderstandDataTable";
import Table from "../../../../shared/components/Table/Table";
import "./Read.css";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getApi } from "../../../../util/HttpService";
const ReadandUnderstand = () => {
  const [filteredData, setFilteredData] = useState([]);
  const columnFields = [
    { DocumentNo: "Document Number" },
    { DocumentName: "Document Name" },
    { DocumentVersion: "Document Version" },
    { EmployeeID: "Employee ID" },
    { EmployeeName: "Employee Name" },
    { ShipOrShore: "Ship or Shore" },
    { DocumentReadStatus: "Document Read Status" },
    { DocumentPath: "Document Path" },
    { ModifiedOn: "Modified On" },
    { CreatedOn: "Created On" },
    { OwnerName: "Owners" },
  ];

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const response = await getApi("getReadDocuments", true);
        //console.log("response ", response);
        if (response.success) {
          const data = await response.data;
          //console.log("data ", data);
          filterData(data);
        } else {
          //console.log(`Document fetch failed.`);
        }
      } catch (error) {
        //console.log(`Document fetch failed: ${error}`);
      } finally {
      }
    };
    getDocuments();
  }, []);

  const filterData = (dataArray) => {
    //console.log("dataArray:: ", dataArray)

    let readData = dataArray.map(
      ({
        DocumentNo,
        DocumentName,
        DocumentVersion,
        EmployeeID,
        EmployeeName,
        ShipOrShore,
        DocumentReadStatus,
        DocUrl,
        DocumentPath,
        ModifiedOn,
        CreatedOn,
        OwnerName,
      }) => ({
        DocumentNo,
        DocumentName,
        DocumentVersion,
        EmployeeID,
        EmployeeName,
        ShipOrShore,
        DocumentReadStatus,
        DocUrl,
        DocumentPath,
        ModifiedOn,
        CreatedOn,
        OwnerName,
      })
    );

    //console.log("All data recived", dataArray);
    setFilteredData(dataArray);
  };

  return (
    <div className="dashboard-container">
      <div className="sub-dashboard-content">
        {/* <h2 className="dashboard-heading-text pointer">Read and Understand</h2> */}
        <PageHeader title="Read and Understand" />
        <div className="table-container">
          {filteredData && filteredData.length > 0 ? (
            <Table jsonData={filteredData} columnFields={columnFields} />
          ) : (
            <Skeleton height="50vh" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadandUnderstand;
