import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { putApi } from '../../../../util/HttpService';
import { notify } from '../../../../util/notify';
import { toast } from 'react-toastify';

const UpdateCategory = ({ setModalOpen, editData, setTableData }) => {
  const location = useLocation();
  const value = location.state || {};

  const [categoryId, setCategoryId] = useState(editData.Id);
  const [inputValue, setInputValue] = useState(editData.ElementName);
  const navigate = useNavigate();
  const toastId = useRef(null);


  // Function to handle input change with regex validation
  const validateInput = (elementName) => {
    const regex = /^[a-zA-Z0-9\s.,-]{0,50}$/;
    if (toast.isActive(toastId.current)) {
      return false;
    }
    const isValid = regex.test(elementName);
    if (!isValid) {
      toastId.current = notify("Only letters, numbers, full stop, comma and spaces are allowed with max limit of 50 characters", "error");
    }
    return isValid;
  };

  const handleSave = async (e) => {
    if (inputValue.trim() === '') {
      notify('Please enter element name.', "error");
      return;
    }
    try {
      const isValid = validateInput(inputValue);
      if (!isValid) {
        return;
      }
      e.currentTarget.disabled = true;
      const response = await putApi('updateelement', {
        id: categoryId,
        elementName: inputValue
      }, true);

      if (!response.success) {
        throw new Error('Network response was not ok');
      }
      if (response.success) {
        setTableData((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((item) => item.Id === categoryId);
          //console.log("index: ", index)
          newData[index].ElementName = inputValue;
          return newData;
        })
        notify('Data saved successfully.', 'success');
      }
      setModalOpen(false);
    } catch (error) {
      console.error('Error saving data:', error);
      notify('Error saving data:', "error");
    }
  };



  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <div className="dashboard-container">
      {/* <PageHeader title="Update Category" /> */}
      <div className="container">
        <p className="label">Element Name</p>
        <input
          className="input-box"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Element Name"
          max={50}
          min={0}
          pattern='^[a-zA-Z0-9\s.,-]*$'
        />
        <div className="BtnContainer">
          <button className="ActionButtons" onClick={handleCancel}>Cancel</button>
          <button className="ActionButtons activeBtn" onClick={(e) => handleSave(e)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default UpdateCategory;
