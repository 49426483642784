import React from 'react'
import "./PageHeader.css"
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';

import Logout from '../logout/Logout';
function PageHeader({ title }) {

    const navigate = useNavigate();

    return (
        <>
            <div className="TopNav">
                <div className="pageDetails">
                    <IconButton className="backButton" onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBackIosIcon />
                    </IconButton>
                    <p className="sectionHeading">{title}</p>
                </div>
                <Logout />
            </div>

        </>
    )
}

export default PageHeader
