// utils/notification.js

/**
 * Displays a toast notification with the specified text and type.
 *
 * @param {string} notificationText The text to display in the notification.
 * @param {string} notificationType The type of notification (e.g. 'success', 'error', etc.).
 */

import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';


export const notify = (notificationText, notificationType) => {
    return toast[notificationType](notificationText, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};

export const clearNotifications = () => {
    toast.clearWaitingQueue();
};
