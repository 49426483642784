import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageHeader from '../../../../shared/components/pageHeader/PageHeader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "./UpdateUser.css";
import { putApi, getApi } from '../../../../util/HttpService';
import { notify } from '../../../../util/notify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const validationSchema = Yup.object().shape({
    employeeID: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Employee ID can contain only alphanumeric characters').max(50, 'Employee ID should not exceed 50 characters').required('Employee ID is required'),
    firstName: Yup.string().matches(/^[a-zA-Z]+$/, 'First Name can contain only alphabets').max(50, 'First Name should not exceed 50 characters').required('First Name is required'),
    lastName: Yup.string().matches(/^[a-zA-Z]+$/, 'Last Name can contain only alphabets').max(50, 'Last Name should not exceed 50 characters').required('Last Name is required'),
    contactNo: Yup.string().matches(/^\d{9}$/, 'Invalid Mobile Number').required('Mobile Number is required'),
    email: Yup.string().trim().email('Invalid Email').max(250, 'Email should not exceed 250 characters').required('Email is required'),
    roleName: Yup.string().required('Role is required'),
    password: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and must be between 8 to 16 characters'
    ).required('Password is required')
});

const UpdateUser = () => {
    const [filteredData, setFilteredData] = useState([]);
    const location = useLocation();
    const value = location.state || {};
    console.log("value::", value);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [roleName, setRoleName] = useState("");
    console.log("roleName: ", roleName);

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        if (!filteredData) return;
        if (filteredData.length > 0) {
            const filteredRoleData = filteredData.find((role) => role.Id == value.RoleId);
            console.log(filteredData.RoleName)
            setRoleName(filteredRoleData.RoleName);
        }
    }, [filteredData]);

    const getRoles = async () => {
        try {
            const response = await getApi('getRoles', true);
            if (response.success) {
                const dataArray = response.data;
                // const roleName = dataArray.find((role) => role.Id == value.RoleId);
                // console.log("dataArray: ", dataArray, "user id", value.RoleId, "roleName: ", roleName.RoleName);
                // setRoleName(roleName.RoleName);
                setFilteredData(dataArray);

            } else {
                console.error('Roles fetch failed');
            }
        } catch (error) {
            console.error(`Roles fetch failed: ${error}`);
        }
    };

    const handleSave = async (values) => {
        try {
            const response = await putApi('updateuser', {
                id: value.Id,
                EmployeeID: values.employeeID,
                FirstName: values.firstName,
                LastName: values.lastName,
                ContactNo: values.contactNo,
                Email: values.email,
                FullName: `${values.firstName} ${values.lastName}`,
                UserName: values.email.split('@')[0],
                RoleId: values.roleId,  // Post the updated RoleId here,
                Password: values.password,
                IsActive: true

            }, true);

            if (!response.success) {
                throw new Error('Network response was not ok');
            }

            console.log('Data saved successfully');
            notify("User updated successfully", "success");
            navigate('/User');
        } catch (error) {
            console.error('Error saving data:', error);
            notify(error.message || "An error occurred", "error");
        }
    };

    return (
        <div className="dashboard-updateuser-container">
            <PageHeader title="Update User" />
            <div className="add-user-container">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        employeeID: value.EmployeeID,
                        firstName: value.FirstName,
                        lastName: value.LastName,
                        contactNo: value.ContactNo,
                        email: value.Email,
                        roleId: parseInt(value.RoleId),
                        roleName: roleName,
                        password: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSave}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className="form-row">
                                <div className="field-container">
                                    <p className="label">Employee ID</p>
                                    <Field
                                        className="input-box"
                                        type="text"
                                        name="employeeID"
                                        placeholder="Employee ID"
                                    />
                                    <ErrorMessage name="employeeID" component="div" className="error-message" />
                                </div>
                                <div className="field-container">
                                    <p className="label">Email</p>
                                    <Field
                                        className="input-box"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            setFieldValue('email', val.trim());
                                        }}
                                    />
                                    <ErrorMessage name="email" component="div" className="error-message" />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="field-container">
                                    <p className="label">First Name</p>
                                    <Field
                                        className="input-box"
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="error-message" />
                                </div>
                                <div className="field-container">
                                    <p className="label">Last Name</p>
                                    <Field
                                        className="input-box"
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="error-message" />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="field-container">
                                    <p className="label">Mobile Number</p>
                                    <Field
                                        className="input-box"
                                        type="tel"
                                        name="contactNo"
                                        placeholder="Mobile Number"
                                    />
                                    <ErrorMessage name="contactNo" component="div" className="error-message" />
                                </div>

                                <div className="field-container">
                                    <p className="label">Password</p>
                                    <div className="passwordFields">
                                        <Field
                                            className="input-box"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Password"
                                        />
                                        <span className="show-password" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </span>
                                    </div>
                                    <ErrorMessage name="password" component="div" className="error-message" />
                                </div>

                            </div>
                            <div className="form-row">
                                <div className="field-container">
                                    <p className="label">Roles</p>
                                    <Field
                                        className="input-box"
                                        component="select"
                                        name="roleName"
                                        onChange={(event) => {
                                            const selectedOption = event.target.selectedOptions[0];
                                            const selectedRoleId = selectedOption.id;
                                            const selectedRoleName = selectedOption.value;

                                            // Set both roleId and roleName
                                            setFieldValue('roleId', selectedRoleId);
                                            setFieldValue('roleName', selectedRoleName);
                                        }}
                                    >
                                        <option value="" disabled>{roleName}</option>
                                        {filteredData.map((item) => (
                                            <option key={item.Id} id={item.Id} value={item.RoleName}>
                                                {item.RoleName}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="roleName" component="div" className="error-message" />
                                </div>
                            </div>
                            <div className="BtnContainer">
                                <button
                                    className="ActionButtons"
                                    type="button"
                                    onClick={() => navigate('/User')}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="ActionButtons activeBtn"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default UpdateUser;
