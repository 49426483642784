import { React, useState } from 'react'
import { useAuth } from '../../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { notify } from '../../../util/notify';

const Logout = () => {
    const { isLoggedIn, setIsLoggedIn, isAdmin, setIsAdmin,userName } = useAuth();
    const username = userName?.toUpperCase().replace(/\"/g, '');
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const settings = ["Logout"];
    const handleMenuItemClick = (setting) => {
        notify("Logout Successful", "success");
        if (setting === "Logout") {
            localStorage.clear();
            setIsLoggedIn(false);
            setIsAdmin(false);
            navigate('/login')
        }
        handleCloseUserMenu();
    };
    return (
        <>
            <div className="UserAvatar">
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar
                                alt={username}
                                src="/static/images/avatar/2.jpg"
                            />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem
                                key={setting}
                                onClick={() => handleMenuItemClick(setting)}
                            >
                                <Typography textAlign="right">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </div>
        </>
    )
}

export default Logout