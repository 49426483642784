import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './AddUser.css';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../../shared/components/pageHeader/PageHeader';
import { getApi } from '../../../../util/HttpService';
import { postApi } from '../../../../util/HttpService';
import { notify } from '../../../../util/notify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AddUser = () => {
    const navigate = useNavigate();

    const [roleData, setRoleData] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    // console.log("Selected Role: ", role);

    useEffect(() => {
        getRoles();
    }, []);
    const getRoles = async () => {
        try {
            const response = await getApi('getRoles', true);
            //console.log("response ", response)
            if (response.success) {
                // const data = await response.json();
                const dataArray = response.data;

                //console.log("response roleData: ", roleData);
                setRoleData(dataArray);
                //console.log("dataArray: ", dataArray);

            } else {
                // const errorData = await response.json();
                //console.log(`Document fetch failed`);
            }
        } catch (error) {
            //console.log(`Document fetch failed: ${error}`);
        } finally {
        }
    };
    const validationSchema = Yup.object().shape({
        employeeID: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Employee ID can contain only alphanumeric characters').max(50, 'Employee ID should not exceed 50 characters').required('Employee ID is required'),
        firstName: Yup.string().matches(/^[a-zA-Z]+$/, 'First Name can contain only alphabets').max(50, 'First Name should not exceed 50 characters').required('First Name is required'),
        lastName: Yup.string().matches(/^[a-zA-Z]+$/, 'Last Name can contain only alphabets').max(50, 'Last Name should not exceed 50 characters').required('Last Name is required'),
        mobileNumber: Yup.string().matches(/^\d{9}$/, 'Invalid Mobile Number').required('Mobile Number is required'),
        email: Yup.string().trim().email('Invalid Email').max(250, 'Email should not exceed 250 characters').required('Email is required'),

        roleName: Yup.string().required('Role is required'),
        password: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
            'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and must be between 8 to 16 characters'
        ).required('Password is required')
    });


    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            //console.log("VLAUES: ", values);
            const response = await postApi('createuser', {
                EmployeeID: values.employeeID,
                FullName: values.firstName + " " + values.lastName,
                UserName: values.email.split('@')[0],
                ContactNo: values.mobileNumber,
                Email: values.email,
                FirstName: values.firstName,
                LastName: values.lastName,
                RoleId: values.roleId,
                Password: values.password
            }, true);
            // console.log("Values ", {
            //     EmployeeID: values.employeeID,
            //     FullName: values.firstName + " " + values.lastName,
            //     UserName: values.email.split('@')[0],
            //     ContactNo: values.mobileNumber,
            //     Email: values.email,
            //     FirstName: values.firstName,
            //     LastName: values.lastName,
            //     RoleId: values.roleId,
            //     Password: values.password
            // });

            //console.log("response from API ", response)

            if (!response.success) {
                throw new Error('Network response was not ok');
            }

            notify("User added successfully", "success");
            navigate('/User');

        } catch (error) {
            //console.error('Error saving data:', error);
            notify(error.message || "An error occurred", "error");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="dashboard-adduser-container">
            <PageHeader title="Add User" />
            <div className="add-user-container">
                <Formik
                    initialValues={{
                        employeeID: '',
                        firstName: '',
                        lastName: '',
                        mobileNumber: '',
                        email: '',
                        roleName: '',
                        roleId: '',
                        password: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form className="addUserForm">
                            <div className="form-row">
                                <div className="field-box">
                                    <p className="label">Employee ID</p>
                                    <Field
                                        className="input-box"
                                        type="text"
                                        name="employeeID"
                                        placeholder="Employee ID"
                                        onChange={(e) => setFieldValue('employeeID', e.target.value.toUpperCase())}
                                    />
                                    <ErrorMessage name="employeeID" component="div" className="error-message" />
                                </div>
                                <div className="field-box">
                                    <p className="label">Email</p>
                                    <Field
                                        className="input-box"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                    />
                                    <ErrorMessage name="email" component="div" className="error-message" />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="field-box">
                                    <p className="label">First Name</p>
                                    <Field
                                        className="input-box"
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="error-message" />
                                </div>
                                <div className="field-box">
                                    <p className="label">Last Name</p>
                                    <Field
                                        className="input-box"
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="error-message" />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="field-box">
                                    <p className="label">Mobile Number</p>
                                    <Field
                                        className="input-box"
                                        type="tel"
                                        name="mobileNumber"
                                        placeholder="Mobile Number"
                                    />
                                    <ErrorMessage name="mobileNumber" component="div" className="error-message" />
                                </div>
                                <div className="field-box ">
                                    <p className="label">Password</p>
                                    <div className="passwordFields">
                                        <Field
                                            className="input-box"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Password"
                                        />
                                        <span className="show-password" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </span>
                                    </div>
                                    <ErrorMessage name="password" component="div" className="error-message" />
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="field-box">
                                    <p className="label">Roles</p>
                                    <Field
                                        className="input-box"
                                        component="select"
                                        name="roleName"
                                        onChange={(event) => {
                                            setFieldValue('roleName', event.target.value);
                                            setFieldValue('roleId', event.target.selectedOptions[0].id)
                                            //console.log("role Id with name: ", event.target.selectedOptions[0].id, event.target.value);
                                        }}
                                    >
                                        <option value="" selected disabled>Roles</option>
                                        {roleData.map((item) => (
                                            <option key={item.Id} id={item.Id} value={item.RoleName}>
                                                {item.RoleName}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="roleName" component="div" className="error-message" />
                                </div>
                            </div>

                            <div className="BtnContainer">
                                <button
                                    className="ActionButtons"
                                    type="button"
                                    onClick={() => navigate('/User')}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="ActionButtons activeBtn"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );


};

export default AddUser;

