import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { useData } from "../../../context/dataContext";
import { getApi, postApi, putApi } from "../../../util/HttpService";
import { notify } from "../../../util/notify";

export const EditMetaData = ({ formData, setEditMetaData, setCardData }) => {
  const navigate = useNavigate();
  const { userId, userName } = useAuth();
  const { ownerDepartmentList, elementListData } = useData();
  // //console.log("formData : ", formData);
  // //console.log("Owner department list: ", ownerDepartmentList);
  // //console.log("Element  list: ", ownerDepartmentList);
  const updateDocument = async (values) => {
    const endpoint = "editDocument/" + formData.Id;
    try {
      const data = await putApi(endpoint, values, true);
      //console.log("Doc updates resp", data)
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const validationSchema = Yup.object({
    documentName: Yup.string()
      .required("Document name is required")
      .test(
        "is-alphanumeric",
        "Document name should only contain alphanumeric characters",
        (value) => {
          return /^[a-zA-Z0-9\s]*$/g.test(value);
        }
      )
      .test("is-not-only-space", "Kindly enter valid value", (value) => {
        return value.trim().length > 0;
      }),
    categoryId: Yup.string().required("Element is required"),
    Version: Yup.number()
      .required("Version number is required")
      .test("is-decimal", "Invalid version number", (value) => {
        return /^\d*\.?\d+$/.test(value.toString());
      })
      .test(
        "is-greater",
        "New version should be greater than existing version",
        (value) => {
          return value > formData.DocumentVersion;
        }
      ),
    ownerDepartment: Yup.string().required("Owner is required"),
    documentNo: Yup.string()
      .required("Document number is required")
      .test(
        "is-numeric-with-hyphen",
        "Kindly enter positive number",
        (value) => {
          return /^[0-9]+$/g.test(value);
        }
      )
      .test("is-not-only-space", "Kindly enter valid value", (value) => {
        return value.trim().length > 0;
      }),
  });

  console.log(
    "formData : ",
    ownerDepartmentList.find((dept) => dept.Id == formData.OwnerDepartment)
      ?.Name || "",
    "formData.ownerDepartment",
    formData.OwnerDepartment
  );

  const formik = useFormik({
    initialValues: {
      documentName: formData.DocumentName || "",
      categoryId: formData.ElementId || "",
      Version: formData.DocumentVersion || "",
      ownerDepartment:
        ownerDepartmentList.find((dept) => dept.Id == formData.OwnerDepartment)
          ?.Id || "",
      documentNo: formData.DocumentNo || "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting }) => {
      console.log("Form Values", values);
      try {
        const selectedElement = elementListData.find(
          (elem) => elem.Id === Number(values.categoryId)
        );
        const elementName = selectedElement ? selectedElement.ElementName : "";

        const selectedOwner = ownerDepartmentList.find(
          (dept) => dept.Id === values.ownerDepartment
        );
        const ownerName = selectedOwner ? selectedOwner.Name : "";

        console.log(
          "ownerName: ",
          ownerName,
          "elementName: ",
          elementName,
          "values: ",
          values
        );
        const documentData = {
          DocumentVersion: values.Version,
          DocumentName: values.documentName,
          DocumentType: formData.DocumentType,
          OwnerDepartment: values.ownerDepartment,
          DocumentNo: values.documentNo,
        };
        const UpdatedCardData = {
          ...formData,
          DocumentNo: values.documentNo,
          DocumentName: values.documentName,
          ElementId: values.categoryId,
          OwnerDepartment: ownerName,
          DocumentVersion: values.Version,
          DocumentPath: elementName,
        };
        const updateDocumentResponse = await updateDocument(documentData);
        if (updateDocumentResponse.success) {
          notify("Document updated successfully", "success");
          setCardData(UpdatedCardData);
          setEditMetaData(false);
        } else {
          notify("Document update failed", "error");
        }
      } catch (error) {
        console.error("Error in form submission:", error);
        notify(error.message || "An error occurred", "error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="docEdit">
      <form onSubmit={formik.handleSubmit} className="formContainer">
        <div className="form">
          <div className="formInputs">
            <div className="formSubContainer">
              <div className="DisplayName">
                <p className="label">Document display name</p>
                <input
                  type="text"
                  id="documentName"
                  name="documentName"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentName}
                />
                {formik.touched.documentName && formik.errors.documentName ? (
                  <div className="error">{formik.errors.documentName}</div>
                ) : null}
              </div>
              <div className="DocumentNumber">
                <p className="label">Document number</p>
                <input
                  type="text"
                  id="documentNo"
                  name="documentNo"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentNo}
                />
                {formik.touched.documentNo && formik.errors.documentNo ? (
                  <div className="error">{formik.errors.documentNo}</div>
                ) : null}
              </div>
            </div>
            <div className="formSubContainer">
              <div className="Version">
                <p className="label">Version number</p>
                <input
                  type="text"
                  min={0}
                  id="Version"
                  max={5}
                  name="Version"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Version}
                />
                {formik.touched.Version && formik.errors.Version ? (
                  <div className="error">{formik.errors.Version}</div>
                ) : null}
              </div>
              <div className="OwnerDepartment">
                <p className="label">Owners</p>
                <select
                  id="ownerDepartment"
                  name="ownerDepartment"
                  className="formInputsDrpDown"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ownerDepartment}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {ownerDepartmentList.map((department) => (
                    <option key={department.Id} value={department.Id}>
                      {department.Name}
                    </option>
                  ))}
                </select>
                {formik.touched.ownerDepartment &&
                formik.errors.ownerDepartment ? (
                  <div className="error">{formik.errors.ownerDepartment}</div>
                ) : null}
              </div>
            </div>
            <div className="formSubContainer">
              <div className="Category">
                <p className="label">SMS element</p>
                <select
                  id="categoryId"
                  name="categoryId"
                  className="formInputsDrpDown"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.categoryId}
                >
                  <option value="">Select Element</option>
                  {elementListData.map((category) => (
                    <option value={category.Id} key={category.Id}>
                      {category.ElementName}
                    </option>
                  ))}
                </select>
                {formik.touched.categoryId && formik.errors.categoryId ? (
                  <div className="error">{formik.errors.categoryId}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="BtnContainer">
          <button
            type="button"
            className="ActionButtons"
            onClick={() => setEditMetaData(false)}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ActionButtons activeBtn"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditMetaData;
