import { notify } from "./notify";

export const getApi = async (endpoint, requiresAuth) => {
  try {
    //console.log("endpoint ", endpoint, " require auth", requiresAuth)
    const headers = await getHeaders(requiresAuth);
    const ApiUrl = process.env.REACT_APP_API_URL;
    //console.log("API Url", ApiUrl)
    if (!ApiUrl) {
      throw new Error("API URL not found");
    }
    const response = await fetch(`${ApiUrl}/${endpoint}`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      return response.json();
    }

    const data = await response.json();
    //console.log("Api data: ", data);
    return data;
  } catch (error) {
    console.error("Error :", error);
  }
};

export const postApi = async (endpoint, data, requiresAuth) => {
  //console.log("endpoint", endpoint);
  //console.log("data", data);
  //console.log("requiresAuth", requiresAuth);

  try {
    const headers = await getHeaders(requiresAuth);
    const ApiUrl = process.env.REACT_APP_API_URL;
    //console.log("ApiUrl", ApiUrl);
    if (!ApiUrl) {
      throw new Error("API URL not found");
    }

    //console.log("Making POST request to", `${ApiUrl}/${endpoint}`, "with the body ", JSON.stringify(data));
    const response = await fetch(`${ApiUrl}/${endpoint}`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    //console.log("Response status", response.status);

    const responseData = await response.json();
    // if (!response.ok) {
    //     throw new Error(`Error: ${response.status}`);
    // }
    return responseData;
  } catch (error) {
    console.error("Error in API:", error);
  }
};

export const putApi = async (endpoint, data, requiresAuth) => {
  try {
    const headers = await getHeaders(requiresAuth);
    const ApiUrl = process.env.REACT_APP_API_URL;
    if (!ApiUrl) {
      throw new Error("API URL not found");
    }

    const response = await fetch(`${ApiUrl}/${endpoint}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    notify("Something went wrong", error);
  }
};

export const deleteApi = async (endpoint, requiresAuth) => {
  try {
    const headers = await getHeaders(requiresAuth);
    const ApiUrl = process.env.REACT_APP_API_URL;
    if (!ApiUrl) {
      throw new Error("API URL not found");
    }

    const response = await fetch(`${ApiUrl}/${endpoint}`, {
      method: "DELETE",
      headers,
    });

    // if (!response.ok) {
    //     throw new Error(`Error: ${response.status}`);
    // }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error in deleteApi:", error);
  }
};

export const uploadFormData = async (endpoint, fileInput, requiresAuth) => {
  const myHeaders = new Headers();
  if (requiresAuth) {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    myHeaders.append("Authorization", `Bearer ${token}`);
  }

  const formdata = new FormData();
  formdata.append("file", fileInput);
  //console.log("formdata in http service", formdata);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const ApiUrl = process.env.REACT_APP_API_URL;
  if (!ApiUrl) {
    throw new Error("API URL not found");
  }

  const response = await fetch(`${ApiUrl}/${endpoint}`, requestOptions);
  if (!response.ok) {
    return response;
  }

  const result = await response.json();
  return result;
};

export const addFormDocumentData = async (endpoint, data, requiresAuth) => {
  const raw = JSON.stringify(data);

  const headers = await getHeaders(requiresAuth);

  const requestOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  const ApiUrl = process.env.REACT_APP_API_URL;
  if (!ApiUrl) {
    throw new Error("API URL not found");
  }

  const response = await fetch(`${ApiUrl}/${endpoint}`, requestOptions);

  if (!response.ok) {
    return response;
  }

  const result = await response.json();
  return result;
};
export const putHTMLApi = async (endpoint, data, requiresAuth) => {
  try {
    const headers = await getHeaders(requiresAuth);
    const ApiUrl = process.env.REACT_APP_API_URL;
    if (!ApiUrl) {
      throw new Error("API URL not found");
    }

    const response = await fetch(`${ApiUrl}/${endpoint}`, {
      method: "PUT",
      headers,
      body: data,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error in putApi:", error);
  }
};

const getHeaders = async (requiresAuth) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (requiresAuth) {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};
