import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "./shared/pages/Login/login";
import { DocProvider } from "./context/docContext";
import Layout from "./layout/layout";
import { useAuth } from "./context/authContext";
import Dashboard from "./shared/pages/dashboard/Dashboard";
import GeneralDocs from "./modules/admin/pages/generalDocs/generalDocs";
import DMS from "./shared/pages/DMS_dashboard/DMS_dashboard";
import { FileViewer } from "./modules/user/pages/fileViewer/fileViewer";
import ManageCategory from "./modules/admin/pages/managecategory/ManageCategory";
import ReadAndUnderstand from "./modules/admin/pages/readandunderstand/Read";
import LoginHistory from "./modules/admin/pages/loginhistory/LoginHistory";
import User from "./modules/admin/pages/users/User";
import Roles from "./modules/admin/pages/roles/Roles";
import AddUser from "./modules/admin/components/User/AddUser";
import { AddDocument } from "./modules/admin/pages/addDocument/addDocument";
import UpdateRole from "./modules/admin/components/Roles/UpdateRole";
import EditCategory from "./modules/admin/pages/editCategory/EditCategory";
import AddRole from "./modules/admin/pages/AddRole/AddRole";
import { DmsDocs } from "./context/dmsContext";
import UpdateCategory from "./modules/admin/pages/UpdateCategory/UpdateCategory";
import UpdateUser from "./modules/admin/pages/UpdateUser/UpdateUser";
import AddForms from "./modules/admin/pages/addForms/AddForms";
import { DataProvider } from "./context/dataContext";
import Loader from "./shared/components/loader/loader";

/**
 * This component wraps the app with an auth layer.
 * It checks if the user is logged in and redirects to the login page if not.
 * If the user is logged in, it renders the app.
 */
const AuthWrapper = ({ children }) => {
  const { isLoggedIn, checkAuthStatus } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [userLocation, setUserLocation] = useState([]);

  useEffect(() => {
    setUserLocation((prev) => [...prev, location.pathname]);
  }, [location]);
  // console.log("user location", userLocation);
  /**
   * Checks if the user is logged in or not.
   * If the user is logged in, sets isChecking to false.
   * If the user is not logged in, navigates to the login page.
   */
  /******  70fc3e36-2b2f-4ff4-b31c-ec03dfa99a4b  *******/
  useEffect(() => {
    const checkAuth = async () => {
      await checkAuthStatus();
      setIsChecking(false);
    };
    checkAuth();
  }, [checkAuthStatus]);

  useEffect(() => {
    if (!isChecking) {
      if (!isLoggedIn && location.pathname !== "/login") {
        navigate("/login");
      } else if (isLoggedIn && location.pathname === "/login") {
        navigate("/");
      }
    }
  }, [isChecking, isLoggedIn, navigate, location]);

  if (isChecking) {
    return;
    <>
      <Loader />
    </>;
  }

  return children;
};

/**
 * The main app component.
 * It renders the app with the auth layer.
 */
const App = () => {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthWrapper>
          <Layout />
        </AuthWrapper>
      ),
      children: [
        { path: "Dashboard", element: <Dashboard /> },
        { path: "", element: <DMS /> },
        { path: "Documents", element: <FileViewer /> },
        { path: "manage-elements", element: <ManageCategory /> },
        { path: "add-elements", element: <EditCategory /> },
        { path: "update-elements", element: <UpdateCategory /> },
        { path: "read-understand", element: <ReadAndUnderstand /> },
        { path: "LoginHistory", element: <LoginHistory /> },
        { path: "User", element: <User /> },
        // { path: "roles", element: <Roles /> },
        { path: "upload-document", element: <AddDocument /> },
        { path: "upload-form", element: <AddForms /> },
        { path: "add-user", element: <AddUser /> },
        { path: "update-user", element: <UpdateUser /> },
        // { path: "update-role", element: <UpdateRole /> },
        // { path: "add-role", element: <AddRole /> },
        { path: "general-documents", element: <GeneralDocs /> },
      ],
    },
    {
      path: "/login",
      element: (
        <AuthWrapper>
          <Login />
        </AuthWrapper>
      ),
    },
  ]);

  return (
    <DocProvider>
      <DmsDocs>
        <DataProvider>
          <RouterProvider router={routes} />
        </DataProvider>
      </DmsDocs>
    </DocProvider>
  );
};

export default App;
