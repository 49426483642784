import React, { useEffect, useLayoutEffect, Suspense } from "react";
import "./layout.style.css"
import SideNav from "../shared/components/sideNav/sideNav";
import { Outlet, useNavigate } from "react-router-dom";

const Layout = () => {

  return (
    <div className="parentContainer d-flex column">
      <SideNav />
      <div className="mainSection">
        <div className="mainContent">
          <Outlet />
        </div>

        <div className="footer">
          <p className="footerText">Copyright © 2024 Jana Marine Services Co. LLC. All Rights Reserved</p>
        </div>
      </div>
    </div>)
};

export default Layout;
