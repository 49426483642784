import React, { useEffect, useState } from "react";
import Table from "../../../../shared/components/ActionTable/ActionTable";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import AddIcon from '@mui/icons-material/Add';
import './ManageCategory.css'
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getApi } from "../../../../util/HttpService";
import { Box, Modal } from "@mui/material";
import AddCategory from './../editCategory/EditCategory';
import UpdateCategory from "./../UpdateCategory/UpdateCategory";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    borderRadius: '42px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};
const ManageCategory = () => {
    const [filteredData, setFilteredData] = useState([]);
    //console.log("table data from manage element: ", filteredData);
    const [open, setOpen] = useState(false)
    const [editData, setEditData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();
    const columnFields = [
        { ElementName: "Element Name" }
    ];
    useEffect(() => {

        const getDocuments = async () => {
            try {
                const response = await getApi('elementlist', true);
                //console.log("response: ", response)
                if (response.success) {
                    // const data = await response.json();
                    //console.log("data category ", response.data);
                    const dataArray = response.data;
                    //console.log("response filtered data: ", dataArray);
                    setFilteredData(dataArray);

                } else {
                    // const errorData = await response.json();
                    //console.log(`Document fetch failed`);
                }
            } catch (error) {
                //console.log(`Document fetch failed: ${error}`);
            } finally {
            }
        };
        getDocuments();
    }, []);

    const handleClickAddCategory = () => {
        setOpen(true);
        // //console.log('manage category add.');
        // navigate("/add-category", { state: " " });
    }
    const handleAddDocs = () => {
        navigate("/upload-document");
    }
    const handleAddForms = () => {
        navigate("/upload-form");
    }

    const handleEdit = (row) => {
        setIsEdit(true);
        setOpen(true);
        setEditData(row.original);
        //console.log(row);


        //console.log("row.original : ", row.original);
    };

    return (
        <div className="manage-category-dashboard dashboard-container">
            <PageHeader title="Manage SMS Elements" />
            <div className="sub-dashboard-content">
                <div className="BtnContainer">
                    <button className="add-category-btn" onClick={handleAddDocs}>Add Documents</button>
                    <button className="add-category-btn" onClick={handleAddForms}>Add Forms</button>
                    <button className="add-category-btn" onClick={handleClickAddCategory}>Add Elements</button>
                </div>
                <div className="table-container">
                    {filteredData && filteredData.length > 0 ? (
                        <Table jsonData={filteredData} columnFields={columnFields} handleEdit={handleEdit} setTableData={setFilteredData} />
                    ) : (
                        <Skeleton height="50vh" />
                    )}
                </div>
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    {isEdit ? <UpdateCategory setModalOpen={() => { setOpen(false); setEditData({}); setIsEdit(false) }} editData={editData} setTableData={setFilteredData} /> : <AddCategory setModalOpen={setOpen} setTableData={setFilteredData} />}
                </Box>
            </Modal>
        </div>
    );


};

export default ManageCategory;