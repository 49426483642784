import React, { useState } from 'react';
import "./EditCategory.css";
import { useNavigate } from 'react-router-dom';
import { notify } from '../../../../util/notify';
import { postApi } from '../../../../util/HttpService';

const EditCategory = ({ setModalOpen, setTableData }) => {

  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  // Function to handle input change with regex validation
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    const regex = /^[a-zA-Z0-9\s.,]*$/;

    console.log("regex test passed", regex.test(newValue))
    if (regex.test(newValue)) {
      setInputValue(newValue);
    }
    else {
      notify("Only letters, numbers, full stop, comma and spaces are allowed with max limit of 100 characters", "error");
    }
  };

  const handleSave = async (e) => {
    setIsSaving(true);
    if (inputValue.trim() === '') {
      notify('Please enter element name.', "error");
      setIsSaving(false);
      return;
    }
    try {
      e.currentTarget.disabled = true;
      const response = await postApi('addnewelement', { elementName: inputValue }, true);
      //console.log("response")
      if (!response.success) {
        throw new Error('Network response was not ok');
      }

      if (response.success) {
        setTableData((prevData) => {
          const newData = [...prevData];
          newData.push({ Id: response.data.Id, ElementName: inputValue });
          return newData;
        });
        notify('Data saved successfully', "success");
      }
      setModalOpen(false);
      setIsSaving(false);
    } catch (error) {
      console.error('Error saving data:', error);
      notify('Error saving data:', "error");
      setIsSaving(false);
    }
  };


  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <div className="dashboard-container">
      <div className="container">
        <p className="label">Element Name</p>
        <input
          className="input-box"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Element Name"
          pattern='^[a-zA-Z0-9\s.,-]*$'
          maxLength={50}
        />        <div className="BtnContainer">
          <button className="ActionButtons" disabled={isSaving} onClick={handleCancel}>Cancel</button>
          <button className="ActionButtons activeBtn" disabled={isSaving} onClick={(e) => { handleSave(e) }}>{isSaving ? 'Saving...' : 'Save'}</button>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;

