import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "../../../constants/navData/NavData";
import { useAuth } from "../../../context/authContext";
import { useDms } from "../../../context/dmsContext";
import { getApi } from "../../../util/HttpService";
import SidebarAccordion from "../Accordian/Accordian";
import logo from "../../../Assets/Images/logo.png";
import { useData } from "../../../context/dataContext";
import "./sideNav.css";
import Loader from "../loader/loader";

const SideNav = () => {
  const { isAdmin } = useAuth();
  const { setOwnerDepartmentList, setElementListData, setDocumentData } =
    useData();
  const location = useLocation();

  const currentLocation = location.pathname;
  const {
    setCategoryName,
    setDocSubTitle,
    setDocInfo,
    docSubTitle,
    ElementName,
    formInfo,
    setFormInfo,
  } = useDms();
  const [categoryList, setCategoryList] = useState([]);
  const [documentMetaData, setDocumentMetaData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const items = isAdmin ? navItems.admin : navItems.user;
  // console.log("side items",items);

  const filterData = (element, data) => {
    const filtered = data.filter((doc) => doc.DocumentPath?.includes(element));
    if (filtered.length > 0) {
      setDocInfo(filtered);
      // console.log("Filtered Data: for the element: ", element, " and doc path is: ", filtered);
    } else {
      setDocInfo([]);
    }
  };

  const filterForms = (element, data) => {
    const filtered = data.filter((form) => form.FormPath?.includes(element));
    if (filtered.length > 0) {
      setFormInfo(filtered);
      console.log(
        "Filtered form Data: for the element: ",
        element,
        " and form path is: ",
        filtered
      );
    } else {
      setFormInfo([]);
    }
  };

  const fetchForms = async () => {
    try {
      const response = await getApi("forms", true);
      if (response.success) {
        const data = response.data;
        setFormData(data);
        console.log("Data from the Forms API is: ", data);
        return data;
      } else {
        console.error("Failed to fetch forms:", await response.json());
      }
      console.log("Filter data for Form: ", ElementName, formData);
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  };

  const fetchCategories = async (fromResponse, docResponse) => {
    try {
      const response = await getApi("elementlist", true);
      if (response.success) {
        const data = response.data;
        // console.log("Data from the Category API is: ", data);
        setElementListData(data);
        setCategoryName(data[0].ElementName);
        filterData(data[0].ElementName, docResponse);
        const sidebarItems = data.map((item) => ({
          title: item.ElementName,
          content: [
            {
              title: "Manuals",
              onClick: () => {
                setDocSubTitle("Manuals");
                setCategoryName(item.ElementName);
                filterData(item.ElementName, docResponse);
              },
            },
            {
              title: "Forms",
              onClick: () => {
                setDocSubTitle("Forms");
                setCategoryName(item.ElementName);
                console.log(
                  "Filter data for the Category and Form: ",
                  item,
                  fromResponse
                );
                filterForms(item.ElementName, fromResponse);
              },
            },
          ],
          onClick: () => {
            setCategoryName(item.ElementName);
            setDocSubTitle("Manuals");
            filterData(item.ElementName, docResponse);
          },
        }));
        // console.log("Sidebar Items: ", sidebarItems);
        setCategoryList(sidebarItems);
      } else {
        console.error("Failed to fetch categories:", await response.json());
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchOwnerDepartments = async () => {
    try {
      const response = await getApi("ownerDepartment", true);
      if (response.success) {
        const data = response.data;
        console.log("Data from the Owner Department API is: ", data);
        setOwnerDepartmentList(data);
      } else {
        console.error(
          "Failed to fetch owner departments:",
          await response.json()
        );
      }
    } catch (error) {
      console.error("Error fetching owner departments:", error);
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await getApi("documentlist", true);
      if (response.success) {
        const actualData = response.data.data;
        setDocumentMetaData(actualData);
        setDocumentData(actualData);

        return actualData;
      } else {
        console.error("Failed to fetch documents:", response.error);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const getAllData = async () => {
    const fromResponse = await fetchForms();
    const docResponse = await fetchDocuments();
    await fetchCategories(fromResponse, docResponse);
  };

  useEffect(() => {
    getAllData();
    fetchOwnerDepartments();
    if (currentLocation === "/dms") {
    }
  }, []);

  const handleClickDownload = async () => {
    try {
      setLoading(true);
      console.log("TRY...");
      const response = await getApi("export/export", true);
      console.log("response ", response);

      // Assuming the base64-encoded file is inside `response.file`
      const base64String = response.data;
      console.log("Base64 response: ", base64String);

      if (!base64String) {
        throw new Error("Base64 string is missing from the response.");
      }
      console.log("Base64 response: ", base64String);

      // Strip any header from the base64 string if necessary (e.g., "data:application/zip;base64,")
      const base64Data = base64String.split(",").pop(); // Get only the base64 part

      // Decode base64 string to binary data
      const binaryString = atob(base64Data);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/zip" });

      // Debug: Check blob size and type
      console.log("Blob created: ", blob);
      console.log("Blob size: ", blob.size);
      console.log("Blob type: ", blob.type);

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "exported_file.zip"; // Set the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL object after download
      URL.revokeObjectURL(blobUrl);

      console.log("Download initiated successfully.");
      setLoading(false);
    } catch (error) {
      console.error(`Document fetch failed: ${error}`);
      setLoading(false);
    }
  };

  return (
    <div className="side-nav-container">
      <div className="side-nav-logo">
        <img src={logo} alt="logo" />
      </div>
      <ul className="side-nav-list">
        {items.map((item, index) => {
          const isActive = item.path === currentLocation;
          const itemClassName = isActive ? "activeItem" : "";

          return (
            <li
              className={`side-nav-item ${
                item.children ? "navItemChildren" : ""
              }`}
              key={index}
            >
              {item.title == "Export Package" ? (
                <p
                  className={`${itemClassName} download-vessel`}
                  onClick={handleClickDownload}
                >
                  <item.icon />
                  <p className="side-nav-text">{item.title}</p>
                </p>
              ) : (
                <Link
                  className={`${itemClassName} side-nav-link`}
                  to={item.path}
                >
                  <item.icon />
                  <p className="side-nav-text">{item.title}</p>
                </Link>
              )}
              {isActive && item.children && categoryList && (
                <SidebarAccordion
                  className="side-nav-accordian"
                  sidebarItems={categoryList}
                />
              )}
            </li>
          );
        })}
      </ul>
      {loading && <Loader />}
    </div>
  );
};

export default SideNav;
