import React, { createContext, useContext, useState } from 'react';

const DocContext = createContext();

export const useDoc = () => useContext(DocContext);

export const DocProvider = ({ children }) => {
    const [globalDocUrl, setGlobalDocUrl] = useState(null);
    const [docData, setDocData] = useState({});
    const [selectedGlobalDocument, setSelectedGlobalDocument] = useState(null);

    return (
        <DocContext.Provider value={{ globalDocUrl, setGlobalDocUrl, selectedGlobalDocument, setSelectedGlobalDocument, docData, setDocData }}>
            {children}
        </DocContext.Provider>
    );
};
