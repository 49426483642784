// navItems.js
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderIcon from "@mui/icons-material/Folder";
import CategoryIcon from "@mui/icons-material/Category";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import HomeIcon from "@mui/icons-material/Home";
import UserIcon from "@mui/icons-material/Person";
import RestoreIcon from "@mui/icons-material/Restore";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DownloadIcon from "@mui/icons-material/Download";
import ExportIcon from "@mui/icons-material/ImportExport";

export const navItems = {
  user: [
    { id: 1, title: "Dashboard", icon: HomeIcon, path: "/Dashboard" },
    {
      id: 2,
      title: "DMS",
      icon: FolderIcon,
      path: "/",
      children: [
        {
          id: 99,
          title: "Upload document",
          icon: UploadFileIcon,
          path: "/upload-document",
        },
      ],
    },
    {
      id: 3,
      title: "General documents",
      icon: FolderIcon,
      path: "/general-documents",
    },
  ],
  admin: [
    { id: 1, title: "Dashboard", icon: HomeIcon, path: "/Dashboard" },
    {
      id: 2,
      title: "DMS",
      icon: FolderIcon,
      path: "/",
      children: [
        {
          id: 99,
          title: "Upload document",
          icon: UploadFileIcon,
          path: "/upload-document",
        },
      ],
    },

    {
      id: 2,
      title: "SMS elements",
      icon: CategoryIcon,
      path: "/manage-elements",
    },
    {
      id: 3,
      title: "General documents",
      icon: FolderIcon,
      path: "/general-documents",
    },

    { id: 4, title: "Users", icon: UserIcon, path: "/User" },
    {
      id: 5,
      title: "Read & understand",
      icon: RestoreIcon,
      path: "/read-understand",
    },
    {
      id: 6,
      title: "Login history",
      icon: AssignmentTurnedInIcon,
      path: "/LoginHistory",
    },
    // { id: 9, title: "HTML Editor", icon: RestoreIcon, path: "/edit-document" },
    {
      id: 7,
      title: "Export Package",
      icon: DownloadIcon,
      exportChildren: [
        {
          id: 8,
          title: "Export Package",
          icon: DownloadIcon,
          path: "/vessel-package",
        },
      ],
    },
  ],
};
