export const customCSS = `
    body {
      font-family: Calibri, sans-serif;
    }
    table tr:first-child {
      background-color: #d9e2f3 !important;
     
    }
    table tr td{
       min-width:55px;
    }
      img{
      max-width:100%;
      }
      .editor-content table {
  border: 1px solid black;
  border-collapse: collapse;
  padding-block: 1rem;
      width: 100%;
}

.editor-content td,
.editor-content th {
  border: 1px solid black;
  padding: 1rem 0.5rem;
}
.editor-content {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}

.editor-content:focus-visible,
.editor-content:active,
.editor-content:hover,
.editor-content:focus-within {
  outline: 1px solid #ccc;
}

 
  `;
