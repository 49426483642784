import React, { useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import "./ActionTable.css"



const Table = ({ jsonData, columnFields, handleEdit, setTableData }) => {
  const navigate = useNavigate();
  const location = useLocation();



  const columns = useMemo(() => {
    if (!jsonData[0]) {
      return [];
    }

    const firstItem = jsonData[0];
    const columnFieldLookup = columnFields.reduce((acc, field) => {
      const key = Object.keys(field)[0];
      acc[key] = field[key];
      return acc;
    }, {});

    return Object.keys(firstItem)
      .flatMap((key) => {
        if (!columnFieldLookup[key]) {
          return [];
        }

        if (typeof firstItem[key] === 'object' && firstItem[key] !== null) {
          return columnFields.map((field) => {
            const key = Object.keys(field)[0];
            return {
              accessorKey: `${key}`,
              header: field[key],
              size: 150,

            }
          })
        } else {
          return [
            {
              accessorKey: key,
              header: columnFieldLookup[key],
              size: 150,
            },
          ];
        }
      });
  }, [jsonData, columnFields]);

  const data = useMemo(() => [...jsonData], [jsonData]);

  // const handleEdit = (row) => {
  //     navigate('/update-role', { state: row.original.RoleName });
  //     // navigate('/add-category');
  //     //console.log('role row: ', row);
  //     //console.log(row.original.RoleName)
  // }

  // const handleEdit = (row) => {
  //     const currentPath = location.pathname;
  //     //console.log('role row: ', row);

  //     if (currentPath.includes('User')) {
  //         navigate('/update-user', { state: row.original });
  //     } else if (currentPath.includes('manage-category')) {
  //         navigate('/update-category', { state: row.original });
  //     } else if (currentPath.includes('roles')) {
  //         navigate('/update-role', { state: row.original });
  //     }

  //     //console.log("row.original : ", row.original);
  // };

  // Use the useMaterialReactTable hook
  const table = useMaterialReactTable({
    columns,
    enableRowActions: true,
    positionActionsColumn: 'last',
    muiTableContainerProps: { sx: { maxHeight: '50vh', overflow: 'auto', minHeight: '45vh' } },
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" onClick={() => handleEdit(row)}>
        Edit
      </MenuItem>,
      // <MenuItem key="delete" onClick={() => //console.info('Delete', row)}>
      //     Delete
      // </MenuItem>,
    ],
    muiTableBodyCellProps: {
      sx: {
        '& .MuiIconButton-root': {
          transform: 'rotate(90deg)'
        }
      }
    },
    data
  });

  return (
    <div className="dashboard-container">
      <div className="sub-dashboard-content">
        <div className="table-container">
          {jsonData && jsonData.length > 0 ? (
            <MaterialReactTable table={table} />
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
